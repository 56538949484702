/* eslint-disable no-useless-return */
import { defineStore } from 'pinia'
import ProveedoresServices from '../services/proveedores.services'
const _ProveedoresServices = new ProveedoresServices()
export const proveedoresStore = defineStore('ProveedoresStore', {
  state: () => {
    return {
      ultimaActualizacionData: {
        count: 0,
        rows: [],
        limit: 10,
        offset: 0,
        pageOptions: [10, 30, 50, 100, 300, 500, 1000, 3000],
        proveedorId: null
      },
      dropdownData: [],
      listaPrecioClienteData: {
        count: 0,
        rows: [],
        limit: 100,
        offset: 0,
        pageOptions: [100, 300, 500, 1000, 3000, 5000, 10000, 30000],
        proveedorId: null
      }
    }
  },
  getters: {
    _ultimaActualizacionData: (state) => state.ultimaActualizacionData,
    _dropdownData: (state) => state.dropdownData,
    _listaPrecioClienteData: (state) => state.listaPrecioClienteData
  },
  actions: {
    async ultimaActualizacion () {
      const params = {
        limit: this.ultimaActualizacionData.limit,
        offset: this.ultimaActualizacionData.offset,
        ProveedorId: this.ultimaActualizacionData.proveedorId
      }
      try {
        const { data } = await _ProveedoresServices.ultimaActualizacion(params)
        this.ultimaActualizacionData.rows = data.rows
        this.ultimaActualizacionData.count = data.count
      } catch (error) {
        console.log(error)
      }
    },
    async dropdown () {
      try {
        const { data } = await _ProveedoresServices.dropdown()
        this.dropdownData = data
      } catch (error) {
        console.log(error)
      }
    },
    async listaPrecioCliente () {
      const params = {
        limit: this.listaPrecioClienteData.limit,
        offset: this.listaPrecioClienteData.offset
      }
      try {
        const { data } = await _ProveedoresServices.listaPrecioCliente({ params, id: this.listaPrecioClienteData.proveedorId })
        this.listaPrecioClienteData.rows = data.rows
        this.listaPrecioClienteData.count = data.count
      } catch (error) {
        console.log(error)
      }
    }
  }
})
