<template>
  <section class="filtros p-5">
    <div class="flex flex-row">
      <strong class="mr-5 my-auto">
        Cliente
      </strong>
      <Dropdown
        v-model="_proveedoresStore._ultimaActualizacionData.proveedorId"
        :options="_proveedoresStore._dropdownData"
        optionLabel="Nombre"
        optionValue="Id"
        filter
        class="w-8/12"
        placeholder="Seleccione un cliente"
      />
      <span class="p-buttonset flex gap-1 my-auto ml-4">
          <Button icon="pi pi-search" severity="info" aria-label="Buscar" size="small" rounded  @click="onFilter"/>
          <Button icon="pi pi-trash" severity="danger" aria-label="Cancel" size="small" rounded @click="onClearFilter"/>
      </span>
    </div>
  </section>
  <section class="tabla px-5 mb-2">
    <DataTable
      :value="_proveedoresStore._ultimaActualizacionData.rows"
      class="p-datatable-small"
      :row-class="rowClass"
      scrollable
      :scrollHeight="scrollHeight"
      tableStyle="min-width: 50rem"
    >
      <Column header="N° Id" style="font-size: 0.75rem">
        <template #body="{ data, index }">
          <span class="text-xs text-blue-600 underline cursor-pointer" v-tooltip.bottom="'Ver Catalogo'" @click="onShowCatalog(data)">
            {{ data.Id ?? index }}
          </span>
          <div class="flex gap-x-4">
            <button class="underline italic idHijo text-white transition ease-in-out duration-500" @click="onOpenLoadfile(data)" >Cargue</button>
          </div>
        </template>
      </Column>
      <Column header="Codigo SAP" style="font-size: 0.75rem">
        <template #body="{ data }">
          <span class="text-xs">
                  {{ data.Codigo }}
          </span>
        </template>
      </Column>
      <Column header="Nombre" style="font-size: 0.75rem">
        <template #body="{ data }">
          <span class="text-xs">
                  {{ data.Nombre }}
          </span>
        </template>
      </Column>
      <Column header="Fecha Actualización" style="font-size: 0.75rem">
        <template #body="{ data }">
          <span class="text-xs">
                  {{ $h.formatDate(data.Ultima, 'YYYY-MM-DD') }}
          </span>
        </template>
      </Column>
    </DataTable>
  </section>
  <section class="paginador px-5 pb-5">
    <Paginator v-model:first="_proveedoresStore._ultimaActualizacionData.offset"
               :rows="_proveedoresStore._ultimaActualizacionData.limit"
               :totalRecords="_proveedoresStore._ultimaActualizacionData.count"
               :rowsPerPageOptions="_proveedoresStore._ultimaActualizacionData.pageOptions"
               @page="onPage($event)"
    />
  </section>
  <Modall ref="_modall"/>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { proveedoresStore } from '../store/proveedores.store'
import Modall from '../catalogo/components/loadCatalogo.vue'
const _proveedoresStore = proveedoresStore()
const router = useRouter()
const _modall = ref()
onMounted(async () => {
  await _proveedoresStore.dropdown()
  await _proveedoresStore.ultimaActualizacion()
})
const scrollHeight = computed(() => `${window.innerWidth * 0.45}px`)
const onPage = ({ first, page, pageCount, rows }) => {
  _proveedoresStore.ultimaActualizacionData.limit = rows
  _proveedoresStore.ultimaActualizacion()
}
const onFilter = () => {
  _proveedoresStore.ultimaActualizacion()
}
const onClearFilter = () => {
  _proveedoresStore.ultimaActualizacionData.proveedorId = null
  _proveedoresStore.ultimaActualizacion()
}
const onShowCatalog = (data) => {
  const _query = {
    Nombre: data.Nombre,
    Codigo: data.Codigo
  }
  router.push({ name: 'pharmasan.compras.proveedores.catalogo', params: { id: data.Id }, query: { ..._query } })
}
const rowClass = () => {
  return 'p-selectable-row'
}
const onOpenLoadfile = (data) => {
  _modall.value.onShow(data)
}
</script>
<style lang="scss" scoped>
.p-selectable-row:hover {
  .idHijo{
    color: blue
  }
}
@media (max-width: 800px) {
  .no-image {
    display: none;
  }
}

</style>
