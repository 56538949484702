<template>
  <div class="card flex justify-content-center">
    <Dialog v-model:visible="visible" ref="_Dialog" maximizable modal :header="'Catalogo '+ proveedor.Nombre" :style="{ width: '50rem' }" :breakpoints="{ '1000px': '70vw', '500px': '90vw' }">
      <div class="card">
        <div class="flex flex-wrap gap-3 mb-3">
          <div class="flex align-items-center">
            <RadioButton v-model="ingredient" inputId="ingredient1"  value="0" />
            <label for="ingredient1" class="ml-2">Cargar nuevo catalogo</label>
          </div>
          <div class="flex align-items-center">
            <RadioButton v-model="ingredient" inputId="ingredient2"  value="1" />
            <label for="ingredient2" class="ml-2">Actualizar catalogo</label>
          </div>
        </div>
        <FileUpload
          mode="advanced"
          name="demo[]"
          url="./upload.php"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :maxFileSize="1000000"
          @upload="onUpload"
          @change="handleFilesUpload()"
          @select=" handleFilesUpload()"
          ref="inputFile"
        >
          <template #header="{ chooseCallback,  clearCallback, files }">
            <div class="flex flex-wrap justify-center flex-1 gap-4 ">
              <Button @click="chooseCallback()" icon="pi pi-file" rounded  v-tooltip.bottom="'Cargar plantilla'"/>
              <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded severity="success" :disabled="!files || files.length === 0" v-tooltip.bottom="'Guardar catalogo'"/>
              <Button @click="clearFile(clearCallback)" icon="pi pi-times" rounded  severity="danger" :disabled="!files || files.length === 0" v-tooltip.bottom="'Cancelar'"/>
              <Button @click="downLoadTemplate" icon="pi pi-cloud-download" rounded  severity="success" v-tooltip.bottom="'Descarga plantilla'"/>
              <Button @click="downLoadArtList" icon="pi pi-shopping-cart" rounded  severity="info" v-tooltip.bottom="'Descarga listado de articulos'"/>
            </div>
          </template>
          <template #empty>
            <div class="flex flex-col items-center justify-center">
              <i class="border-2 border-400 p-5 pi pi-cloud-upload border-circle  text-400 text-8xl" />
              <p class="mt-4 mb-0">Arrastre y suelte archivos aquí para cargarlos.</p>
            </div>
          </template>
          <template #content="{ files }">
            <DataTable
              class="p-datatable-small text-xs"
              :value="inputFileData"
              :scrollable="true"
              :scrollHeight="scrollHeight"
              striped-rows
              tableStyle="min-width: 50rem"
              v-if="files.length > 0"
            >
              <Column field="ArticuloId" header="ArticuloId"/>
              <Column field="ItemCode" header="ItemCode"/>
              <Column field="ItemName" header="ItemName"/>
              <Column field="Cum" header="Cum"/>
              <Column field="Precio" header="Precio"/>
            </DataTable>
          </template>
        </FileUpload>
        <Toast />
      </div>
    </Dialog>
  </div>
</template>
<script setup>
import { ref, defineExpose, computed } from 'vue'
import { catalogoStore } from '../store/catalogo.store'
import XLSX from 'xlsx'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
const router = useRouter()
const toast = useToast()
const _catalogoStore = catalogoStore()
const visible = ref(false)
const inputFile = ref()
const inputFileData = ref([])
const ingredient = ref('0')
const _Dialog = ref()
const proveedor = ref({
  Id: null,
  Nombre: null
})
const scrollHeight = computed(() => `${window.innerWidth * 0.45}px`)
/* Funcion que obtiene la informacion de la data cargada en excel */
const handleFilesUpload = () => {
  inputFileData.value = []
  const file = inputFile.value.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = (event) => {
      const result = event.target.result
      const dataOne = new Uint8Array(result)
      const workbook = XLSX.read(dataOne, { type: 'array', cellDates: true })
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
      const data = XLSX.utils.sheet_to_json(firstWorksheet)
      inputFileData.value = data
    }
    reader.readAsArrayBuffer(file)
  }
}
const uploadEvent = () => {
  const payload = inputFileData.value.map(x => {
    return {
      ProveedorId: proveedor.value.Id,
      ArticuloId: x.ArticuloId,
      VlrUnitario: x.Precio
    }
  })
  if (ingredient.value === '1') {
    _catalogoStore.putXlsxData(payload).then(() => {
      onFinished()
    })
  } else {
    _catalogoStore.saveXlsxData(payload).then(() => {
      onFinished()
    })
  }
}
const clearFile = (callback) => {
  inputFileData.value = []
  callback()
}
const onShow = (data) => {
  proveedor.value = data
  visible.value = !visible.value
  inputFileData.value = []
  _Dialog.value.maximized = !_Dialog.value.maximized
}
const onFinished = () => {
  toast.add({ severity: 'success', summary: 'Catalogo', detail: 'Registro exitoso', life: 3000 })
  const _query = {
    Nombre: proveedor.value.Nombre,
    Codigo: proveedor.value.Codigo
  }
  setTimeout(() => {
    inputFileData.value = []
    visible.value = !visible.value
    router.push({ name: 'pharmasan.compras.proveedores.catalogo', params: { id: proveedor.value.Id }, query: { ..._query } })
  }, 2000)
}
const downLoadTemplate = () => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet([
    {
      ArticuloId: '',
      ItemCode: '',
      ItemName: '',
      Cum: '',
      Precio: ''
    }
  ])
  XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
  XLSX.writeFile(wb, 'Catalogo-template.xlsx')
}
const downLoadArtList = () => {
  _catalogoStore.getArticulos().then((r) => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(r)
    XLSX.utils.book_append_sheet(wb, ws, 'ARTICULOS')
    XLSX.writeFile(wb, 'Articulos.xlsx')
  })
}
defineExpose({
  onShow
})

</script>
<style lang="scss" scoped>
.text-8xl {
  font-size: 6rem !important;
}
.border-400 {
  border-color: var(--surface-400)!important;
}
.text-400 {
  color: var(--surface-400)!important;
}
.border-circle {
  border-radius: 50%;
}
.border-2 {
  border-style: solid;
  border-width: 2px!important;
}
.p-message {
  margin: 0.2rem 0;
  border-radius: 6px;
}
</style>
