import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_MXCOMPRAS + '/proveedores'
export default class ProveedoresServices {
  ultimaActualizacion (params) {
    return http.get(`${baseUrl}/ultima-actualizacion`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  dropdown () {
    return http.get(`${baseUrl}/dropdown`, {})
  }

  listaPrecioCliente ({ params, id }) {
    return http.get(`${baseUrl}/lista-precio-cliente/${id}`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
